// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
window.jQuery = $;
window.$ = $;

import bootstrap from "bootstrap";
import leaflet from "leaflet";




